export default function creativePreviewFrame({ width, height }) {
    return {
        width,
        height,
        scale: 1,
        marginTop: 0,
        scalingEnabled: true,

        toggleScaling() {
            this.scalingEnabled = !this.scalingEnabled;
            if (this.scalingEnabled) {
                this.updateScale();
                this.$nextTick(() => {
                    let container = this.$refs.iframeContainer;
                    container.scrollLeft = 0;
                    container.scrollTop = 0;
                });
            } else {
                this.scale = 1;
            }
        },

        updateScale() {
            this.marginTop = document.getElementById('main-nav')?.clientHeight || 0;

            let container = this.$refs.iframeContainer;
            if (!container || !this.scalingEnabled) return;

            let rect = container.getBoundingClientRect();
            let maxWidthRatio = rect.width < 768 ? 0.95 : 0.8;
            let maxWidth = rect.width * maxWidthRatio;
            let maxHeight = rect.height - this.marginTop;
            let scaleX = maxWidth / this.width;
            let scaleY = maxHeight / this.height;
            this.scale = Math.min(scaleX, scaleY);

            if (this.height * this.scale > maxHeight) {
                this.scale = maxHeight / this.height;
            }
        },

        refreshIframe() {
            const iframe = document.getElementById('creative_iframe');
            if (iframe) iframe.src = iframe.src;
        },
    };
}
