import './bootstrap';
import { initFlowbite, initTooltips } from 'flowbite'
import mapboxgl from 'mapbox-gl';
import { registerAlpineComponents } from './alpine/components';

window.mapboxgl = mapboxgl;

// Alpine
import notifications from 'alpinejs-notify'
window.Alpine = Alpine;

registerAlpineComponents();

Alpine.plugin(notifications);
Alpine.magic('clipboard', () => {
    return subject => navigator.clipboard.writeText(subject)
})

// JS Notifications
window.notify = function (message, type = 'success') {
    window.dispatchEvent(new CustomEvent('notify', {
        detail: { message, type }
    }));
};

// Event Listeners
document.addEventListener('livewire:init', function () {

    Livewire.on('logRocketUser', eventData => {
        LogRocket.identify(eventData[0].id, {
            name: eventData[0].name,
            email: eventData[0].email
        });

        const scope = Sentry.getCurrentScope();

        scope.setUser({
            name: eventData[0].name,
            email: eventData[0].email,
        });

        LogRocket.getSessionURL(sessionURL => {
            scope.setExtra("sessionURL", LogRocket.recordingURL);
        });
    });
});

document.addEventListener('livewire:navigated', () => {
    initFlowbite();
});

document.addEventListener('DOMContentLoaded', function () {

    const targetNode = document.body;
    const observer = new MutationObserver(() => {
        initTooltips();
        document.dispatchEvent(new Event('flowbite:updated'));
    });
    observer.observe(targetNode, { childList: true, subtree: true });
});

document.addEventListener('openNewTab', event => {
    window.open(event.detail[0], '_blank');
})

// Sentry
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_NODE_ENV,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.2,
    tracePropagationTargets: ["localhost", /^https:\/\/(upload|api|app)\.fluxoutdoor\.com/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
        if (event.exception && event.event_id) {
            Sentry.showReportDialog({
                eventId: event.event_id
            });
        }
        return event;
    },
});

// LogRocket
import LogRocket from 'logrocket';

LogRocket.init('mextdu/concord', {
    mergeIframes: true,
    rootHostname: 'fluxoutdoor.com',
});